<template>
  <div class="saerch-warp">
    <Search @search="search" class="search" ref="search" :searValue="searValue" />
    <div class="content" v-show="!isLoading">
      <!--历史记录-->
      <div class="item" v-if="history.length > 0">
        <div class="title-box">
          <h2>历史记录</h2>
          <div @click="deleteHistory">
            <svg-icon class="delete" icon-class="delete" />
          </div>
        </div>
        <div class="item-info">
          <div class="info-text ellipsis" v-for="(item, index) in showHistory" :key="index" @click="changSearch(item)">
            {{ item }}
          </div>
        </div>
        <div class="historyMore" v-if="history.length > 6" @click="expand">
          <span>{{ isExpand ? '收缩' : '展开' }}</span>
          <span :class="['icon', { iconActive: isExpand }]"></span>
        </div>
      </div>
      <!--热门推荐-->
      <div class="item" v-if="hotList.length">
        <div class="title-box">
          <h2>热门推荐</h2>
          <div class="forward-box" @click="forwardChange" v-if="hotListAll.length > 6">
            <svg-icon icon-class="refresh"></svg-icon>
          </div>
        </div>
        <div class="item-info">
          <div class="info-text ellipsis" v-for="hot in hotList" :key="hot.id" @click="changSearch(hot.name)">
            {{ hot.name }}
          </div>
        </div>
      </div>
      <!-- 猜你喜欢-->
      <div class="like_item_warp" v-if="likeList.length">
        <div class="likeBox">
          <svg-icon icon-class="guess_like"></svg-icon>
          <div class="forward-box" @click="likeChange">
            <svg-icon icon-class="refresh"></svg-icon>
          </div>
        </div>
        <!--         
        <PullRefresh :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
                     @onRefresh="onRefresh" :isNoData="isNoData" :error="error"> -->
        <div class="comment" v-for="likeInfo in likeList" :key="likeInfo.id">
          <BolHeader :info="likeInfo" />
          <div class="like_item_box">
            <div class="like_item" v-for="(vInfo, index) in likeInfo.vInfos" :key="vInfo.id">
              <div class="cover_box" @click="openVideos(vInfo, index)">
                <ImgDecypt :src="vInfo.cover" class="like_cover" />
                <TipIcon :videoInfo="vInfo" />
                <div class="video_info">
                  <div class="watch">
                    <svg-icon icon-class="tip_play" />
                    <span>{{ vInfo.playCount | watchCount }}</span>
                  </div>
                  <div class="time">
                    <span>{{ vInfo.playTime | videotime }}</span>
                  </div>
                </div>
              </div>
              <span>{{ vInfo.title }}</span>
            </div>
          </div>
        </div>
        <!-- </PullRefresh> -->
      </div>
    </div>
    <Loading v-show="isLoading" />
  </div>
</template>
<script>
import TipIcon from '@/components/TipIcon';
import Search from '@/components/Search';
import BolHeader from '@/components/Post/header';
import ImgDecypt from '@/components/ImgDecypt';
import { queryHotTagList, queryRecommendList } from '@/api/community';
import { getLocalItem, removeLocal, setLocalItem } from '@/utils/longStorage';
import { Toast } from 'vant';
import Loading from '@/components/Loading';
// import PullRefresh from "@/components/PullRefresh"

export default {
  name: 'search',
  components: {
    Search,
    BolHeader,
    ImgDecypt,
    Loading,
    TipIcon,
    // PullRefresh
  },
  data() {
    return {
      history: [],
      showHistory: [],
      isExpand: false,
      likeParams: {
        pageNumber: 1,
        pageSize: 50,
      },
      hotParams: {
        pageNumber: 1,
        pageSize: 6,
      },
      searValue: '',
      likeList: [],
      likeListAll: [],
      hotList: [],
      hotListAll: [],
      isLoading: true,
      loading: false,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
    };
  },
  created() {
    this.getRecommendList();
    this.getHotList();
    if (getLocalItem('historySearch')) {
      this.history = JSON.parse(getLocalItem('historySearch'));
    }
  },
  mounted() {
    this.refreshHistory();
  },
  watch: {
    history(newValue, oldValue) {
      if (newValue.length > 10) {
        this.history.pop();
      }
      if (!this.isExpand) {
        this.showHistory = this.history.slice(0, 6);
      } else {
        this.showHistory = this.history;
      }
    },
  },
  methods: {
    search(val) {
      let searchText = val.trim();
      if (!searchText) {
        Toast({
          message: '搜索信息不能为空',
          position: 'top',
        });
        return;
      }

      this.$bus.$emit('dataBuriedEvent', {
        dataType: 2,
        tagsName: searchText,
      });

      let index = this.history.findIndex((name) => name == val);
      if (index != -1) {
        this.history.splice(index, 1);
      }
      this.history.unshift(val);
      this.$refs.search.clear();
      this.$router.push({
        path: '/search/details/video',
        query: {
          keyword: val,
        },
      });
    },
    //获取推荐视频
    openVideo(vInfo) {
      this.$store.commit('video/SET_VIDEOLIST', {
        list: [vInfo],
      });
      this.$router.push({
        path: '/communityVideo',
        query: {
          via: 6,
        },
      });
    },
    //不获取推荐视频
    openVideos(vInfo, index) {
      this.$router.push({
        path: '/communityVideo',
        query: {
          via: 6,
          vid: vInfo.id,
        },
      });
    },
    //猜你喜欢
    async getRecommendList(type) {
      let req = {
        pageNumber: String(this.likeParams.pageNumber),
        pageSize: String(this.likeParams.pageSize),
      };
      try {
        let res = await this.$Api(queryRecommendList, req);
        this.isLoading = false;
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.likeListAll = list;
          } else {
            this.likeListAll = this.likeList.concat(list);
          }
          if (this.likeParams.pageNumber == 1 && this.likeListAll.length == 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
      this.likeChange();
    },
    forwardChange() {
      this.hotList = this.hotListAll.splice(0, 8);
      this.hotListAll.push(...this.hotList);
    },
    likeChange() {
      this.likeList = this.likeListAll.splice(0, 3);
      this.likeListAll.push(...this.likeList);
    },
    changSearch(val) {
      this.searValue = val;
      this.search(val);
    },
    //热门推荐
    async getHotList() {
      let res = await this.$Api(queryHotTagList, undefined);
      if (res.code == 200) {
        let list = res.data.hot || [];
        list.forEach((e, index) => {
          if (!e.name) {
            list[index].name = '未知标签';
          }
        });
        this.hotListAll = list;
        this.forwardChange();
      }
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.likeParams.pageNumber++;
      }
      this.getRecommendList();
    },
    onRefresh() {
      this.likeParams.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getRecommendList('refresh');
    },
    expand() {
      this.isExpand = !this.isExpand;
      if (!this.isExpand) {
        this.showHistory = this.history.slice(0, 6);
      } else {
        this.showHistory = this.history;
      }
    },
    deleteHistory() {
      this.history = [];
      removeLocal('historySearch');
    },
    //储存历史记录
    refreshHistory() {
      window.addEventListener('beforeunload', () => {
        if (this.history.length > 0) {
          setLocalItem('historySearch', JSON.stringify(this.history));
        }
      });
    },
  },
  beforeDestroy() {
    if (this.history.length > 0) {
      setLocalItem('historySearch', JSON.stringify(this.history));
    }
  },
};
</script>
<style lang="scss" scoped>
.saerch-warp {
  height: 100%;
  background-color: #ffffff;
  .historyMore {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    color: #7c879f;
    padding-top: 10px;

    span {
      font-size: 12px;
    }

    .icon {
      width: 0;
      height: 0;
      border: 6px solid;
      border-color: transparent transparent #7c879f;
      margin-top: 8px;
      transform: rotate(180deg);
    }

    .iconActive {
      margin-top: -5px;
      transform: rotate(0);
    }
  }

  .content {
    height: $searchHiegt;
    overflow-y: auto;
    padding: 0 16px;
    -webkit-overflow-scrolling: touch;
    .item {
      padding-top: 20px;

      .title-box {
        display: flex;
        justify-content: space-between;
        .delete {
          width: 20px;
          height: 20px;
        }

        h2 {
          font-size: 16px;
          color: #333333;
        }

        > svg {
          font-size: 22px;
        }

        .forward-box {
          span {
            font-size: 16px;
          }
          svg {
            font-size: 18px;
          }
        }
      }

      .item-info {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        color: #666666;
        .info-text {
          padding: 3px 16px;
          background: #eff1f6;
          margin-right: 10px;
          margin-top: 10px;
          border-radius: 4px;
        }
      }
    }

    .like_item_warp {
      padding-top: 17px;
      .likeBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 16px;
        > svg {
          width: 72px;
          height: 18px;
        }
        .forward-box {
          svg {
            font-size: 18px;
          }
        }
      }
      h2 {
        font-size: 18px;
      }

      .comment {
        padding-top: 20px;

        h2 {
          font-size: 18px;
          padding-bottom: 16px;
        }

        .like_item_box {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-row-gap: 9px;
          grid-column-gap: 9px;
          padding-top: 13px;
          font-size: 14px;

          .like_item {
            overflow: hidden;

            .cover_box {
              height: 168px;
              position: relative;

              .like_cover {
                height: 100%;
                border-radius: 4px;
                // 兼容ios的圆角问题
                transform: translateZ(0px);
                overflow: hidden;
              }

              .video_icon {
                position: absolute;
                top: 0;
                font-size: 10px;

                .v_gold {
                  width: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 18px;
                  line-height: 20px;
                  text-align: center;
                  border-top-left-radius: 4px;
                  background: linear-gradient(#fe765b, #ff455b);

                  svg {
                    padding-right: 4px;
                  }
                }

                .v_vip {
                  width: 40px;
                  height: 16px;
                  line-height: 16px;
                  text-align: center;
                  border-top-left-radius: 4px;
                  background: linear-gradient(#ff7426, #e77c2e);
                }
              }

              .video_info {
                width: 100%;
                box-sizing: border-box;
                position: absolute;
                bottom: 0;
                height: 18px;
                background: rgba(#000000, 0.4);
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 11px;
                padding: 5px 8px;
                color: #dcdee0;

                .watch {
                  display: flex;
                  align-items: center;

                  > svg {
                    padding-top: 4px;
                    padding-right: 3px;
                  }
                }
              }
            }

            span {
              padding-top: 4px;
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>
