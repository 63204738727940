<template>
  <div class="post-top">
    <div class="post-top-left flex-center-center">
      <router-link
        class="post-avatar"
        :to="{ path: '/userHomePage', query: { uid: info.uid } }"
        :class="{ isCertifica: info.merchantUser == 1 }"
      >
        <ImgDecypt :src="info.portrait" :key="info.portrait" round />
        <div class="certification" v-if="info.superUser == 1">
          <Vlogo :vid="info.uid" />
        </div>
        <svg-icon
          class="certificaBorder"
          iconClass="certificaBorder"
          v-if="info.merchantUser == 1"
        />
        <!-- <svg-icon class="certification" iconClass="certification" v-if="info.superUser == 1"></svg-icon> -->
      </router-link>
      <div class="post-info">
        <div class="username_box ellipsis">
          <router-link
            tag="span"
            :to="{ path: '/userHomePage', query: { uid: info.uid } }"
            :class="{ vipName: info.isVip && info.vipLevel > 0 }"
          >
            {{ info.name }}
          </router-link>
          <svg-icon
            class="user_icon"
            iconClass="crown"
            v-if="info.isVip && info.vipLevel > 0"
          ></svg-icon>
          <div class="user_icon" v-for="icon in userIcons" :key="icon.number">
            <ImgDecypt :src="icon.imgUrl" v-if="icon.isExpire" />
          </div>
        </div>
        <!--显示粉丝-->
        <div class="fan">
          <div class="fan_text">粉丝：{{ info.fans || 0 }}</div>
        </div>
      </div>
    </div>
    <div
      class="post-top-right"
      v-if="!info.hasFollow && info.uid != $store.getters.userInfo.uid"
      @click="careOrcancle"
    >
      关注
    </div>
  </div>
</template>
<script>
import Vlogo from '@/components/Vlogo';
import ImgDecypt from '@/components/ImgDecypt';
import { Toast } from 'vant';
import { careOrcancle } from '@/api/user';
import { getMedal } from '@/utils/getConfig';

export default {
  props: {
    info: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  name: 'bol_name',
  components: {
    Vlogo,
    ImgDecypt,
  },
  computed: {
    userIcons() {
      return getMedal(this?.info?.publisher?.awardsExpire || []) || [];
    },
  },
  methods: {
    // 关注-取消关注
    async careOrcancle() {
      let req = {
        followUID: parseInt(this.info.uid),
        isFollow: !this.info.hasFollow,
      };
      if (!this.info.uid) {
        Toast('当前用户信息错误');
        return;
      }
      let res = await this.$Api(careOrcancle, req);
      if (res.code === 200) {
        Toast(req.isFollow ? '关注成功' : '取消成功');
        this.info.hasFollow = req.isFollow;
        return;
      }
      Toast(res.tip || `${req.isFollow ? '关注失败' : '取消关注失败'}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.post-top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .post-top-left {
    .post-avatar {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      margin-right: 8px;
      position: relative;

      .certification {
        width: 16.1px;
        height: 16.2px;
        position: absolute;
        right: 0;
        bottom: 0;
        overflow: hidden;
        border-radius: 50%;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
      }
      .certificaBorder {
        position: absolute;
        top: -8.2px;
        width: 100%;
        font-size: 25px;
        z-index: 1;
      }
    }

    .isCertifica {
      border-radius: 50%;
      border: 1.5px solid #d82d0b;
    }

    .post-info {
      width: 200px;
      display: flex;
      flex-direction: column;

      .username_box {
        display: flex;
        align-items: center;
        font-size: 18px;

        .user_icon {
          width: 17px;
          height: 17px;
          padding-left: 5px;
          /deep/ .vanImage {
            background: #00000000 !important;
          }
        }
        span {
          font-size: 20px;
          letter-spacing: -0.41px;
        }
        .vipName {
          color: #ff678f
        }
      }

      .fan {
        padding-top: 4px;

        .fan_text {
          font-size: 12px;
          color: #666666;
        }
      }

      .post_Introduction {
        font-size: 15px;
        letter-spacing: -0.08px;
        color: #7c879f;
        padding-top: 4px;

        svg {
          font-size: 16px;
          padding-right: 5px;
        }

        .location {
          max-width: 150px;
          padding-right: 16px;
        }
      }
    }
  }

  .post-top-right {
    display: inline-block;
    width: 56px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    color: #ff82a2;
    border: 1px solid #ff82a2;
    border-radius: 20px;
    font-size: 14px;

    svg {
      font-size: 13px;
    }
  }
}
</style>
